import m from 'mithril'

import sharelinks from '../components/sharelinks'
import quote_bar from '../components/quote_bar'
import gradient_section from '../components/gradient_section'

import link_box from '../components/link_box'
import story_block from '../components/story_block'

const image_base = 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/'


const NEW_STORIES = [

    'the-birds',
    'dont-look-now',
    'rattle-of-bones',
    'the-detective-detector',
    'according-to-their-lights',
    'the-blue-hotel',
    'the-gateway-of-the-monster'

    // 'the-frog-prince',
    // 'iron-hans',
    // 'the-water-of-life'

    // 'her-letters',
    // 'about-love',
    // 'egyptian-sorcery',
    // 'wireless-confusion'

    // 'the-hanging-stranger',
    // 'second-variety',
    // 'beyond-lies-the-wub'

    // 'keeping-his-promise',
    // 'in-exile',
    // 'the-spring-my-dear',
    // 'the-past-was-goodly-once'

    // 'black-joe',
    // 'the-mystery-of-dave-regan',
    // 'payable-gold',
    // 'elaines-tomb',
    // 'a-charm',
    // 'if',
    // 'the-sealed-room'

    // 'the-wendigo',
    // 'the-blue-cross'

    // 'the-seed-from-the-sepulchre',
    // 'the-dweller-in-the-gulf',
    // 'double-cosmos',
    // 'the-empty-house',
    // 'a-haunted-island',
    // 'a-case-of-eavesdropping'

]

const MONTH = 'January'

const Page = {


    view: function (vnode) {

        return [
            m('.page.home_page',
                m(gradient_section,
                    m('.hero',
                        m('.hero_section_1',
                            m('.main_home_headings',
                                m('h1', 'Read History’s Best,',
                                    m('br'),
                                    'Anytime, Anywhere'
                                ),
                                m('h2', 'A free, online library for reading and downloading public domain short stories.'),

                                m(rotator_splash, { class: 'rotator_splash_1' }),


                                m('a.heavy_button.browse_link', {
                                    href: '/stories'
                                },
                                    'Browse The Library',
                                ),

                                m('a.my_link.secondary_home_link',
                                    {
                                        href: '/about',
                                    },
                                    'About'),

                            )
                        ),
                        m('.hero_section_2',

                            m(rotator_splash, { class: 'rotator_splash_2' }),

                        )
                    )
                ),

                m(quote_bar),

                m('section.new_stories.max-800',
                    m('.p-10',
                        m('h3', 'New Stories'),
                        m('h4', `Uploaded in ${MONTH}`),

                        m('.d_flex.flex_wrap.flex_gap_large.d_centre.d_centre.pe_1000.stories_flex',
                            NEW_STORIES.map(story_code => {

                                return [
                                    m(story_block, { code: story_code })
                                ]
                            })
                        )
                    )
                ),

                m(quote_bar),







                m('section.new_stories.max-800',
                    m('.p-10',
                        m('h3.', m('a.my_link', { href: '/classics' }, 'The Classics')),
                        m('h4', `History’s ten most iconic short stories.`),

                        m('.d_flex.flex_wrap.flex_gap_large.d_reverse.d_even.pe_1000.stories_flex.home_classics',

                            CLASSICS_DENORMALISED
                                .map(story_code => {

                                    return [
                                        m(story_block, { story: story_code })
                                    ]
                                }),
                        )
                    )
                ),

                m(quote_bar),


                m('section.new_stories.max-800',
                    m('.p-10',
                        m('h3.', m('a.my_link', { href: '/choice' }, 'Librarian’s Picks')),
                        m('h4', `My choice for the best short stories of the library.`),

                        m('.d_flex.flex_wrap.flex_gap_large.d_reverse.d_even.pe_1000.stories_flex.home_classics',


                            PICKS_DENORMALISED
                                .map(story => {

                                    return [
                                        m(story_block, { story: story })
                                    ]
                                }),

                        )
                    )
                ),

                m(quote_bar),


                m('.p-10'),
                m('.p-20',

                    m(link_box,
                        {
                            header_1: 'Search The Library',
                            header_2: 'Search, sort, and filter from over 1000 short stories from the public domain.',
                            link_text: 'Library',
                            link: '/stories',
                            img: 'sep_library',
                            alt: 'Search results for short stories with pictures and titles.',
                            icon: 'books-180x180.png',
                            icon_alt: 'A pixel image of books'
                        })
                ),
                m('.p-10'),

                m('.', m(quote_bar)),
                m('.p-10'),

                m('.p-20',

                    m(link_box,
                        {
                            header_1: 'Find Your Author',
                            header_2: 'Australian poets or Russian satirists. American romanticists or Irish realists.',
                            link_text: 'Authors',
                            link: '/authors',
                            img: 'sep_authors',
                            alt: 'The portraits of various authors including H. G. Wells, Edgar Allan Poe and Arthur Conan Doyle.',
                            icon: 'pixel_pen.png',
                            icon_alt: 'A pixel image of a pen'

                        }
                    ),
                ), m('.p-10'),

                m('.', m(quote_bar)),
                m('.p-10'),

                m('.p-20',

                    m(link_box,
                        {
                            header_1: 'Discover The Best',
                            header_2: 'Find your next read from collections of the best works.',
                            link_text: 'Discover',
                            link: '/discover',
                            img: 'sep_genres',
                            alt: 'Three squares of text with pictures of authors.',
                            icon: 'squares.png',
                            icon_alt: 'coloured tiles'

                        }
                        // ),
                    )
                ),
                m('.p-10'),


                m(quote_bar),

                m('section.social_section',
                    m('.p-10',
                        m('h3', 'Share this Website'),
                        m(sharelinks, {
                            link: window.location.href,
                            title: 'Library of Short Stories'
                        })
                    ),
                )
            )
        ]
    }
}


export default Page

const rotator_splash = {
    view: vnode => {
        return [
            m('.rotator_splash',
                {

                    class: vnode.attrs.class
                },

                ROTATORS
                    .map(story_code => {

                        return [
                            m(story_block, { story: story_code })
                        ]
                    }
                    ),
                // ),

                // m('.rotator_splash',

                ROTATORS_AUTHORS
                    .map(author => {

                        return [
                            m('a.basic_story_tile.rotating_author_tile',
                                {
                                    href: '/author/' + author.code
                                },
                                m('img', { src: image_base + author.code +  '.jpg', alt: author.alt }),
                                m('.image_shade'),
                                m('.basic_story_tile_border')
                            )
                        ]
                    }
                    )
            ),
        ]
    }
}


const CLASSICS_DENORMALISED = [
    { code: 'the-tell-tale-heart', title: 'The Tell-Tale Heart', author: 'Edgar Allan Poe', image: 'stare.jpg', alt: `A tense, staring eye of a man's face. Graphite filter`, genre: 'gothic' },
    { code: 'a-scandal-in-bohemia', title: 'A Scandal in Bohemia', author: 'Arthur Conan Doyle', image: 'detective.jpg', alt: 'A silhouetted detective in a bowler hat. Graphite filter.', genre: 'mystery' },
    { code: 'the-lady-with-the-dog', title: 'The Lady with the Dog', author: 'Anton Chekhov', image: 'lovers.jpg', alt: 'A kissing man and woman, silhouetted in a forest. Graphite filter. Graphite filter.', genre: 'literary' },
    { code: 'the-most-dangerous-game', title: 'The Most Dangerous Game', author: 'Richard Connell', image: 'jungle.jpg', alt: 'A jungle of tall, twisting ferns. Graphite filter', genre: 'adventure' },

]
    .map(story => {
        story.image_alt = story.image
        story.genres = [story.genre]

        return story
    })

const PICKS_DENORMALISED = [
    { code: 'the-man-who-could-imitate-a-bee', title: 'The Man Who Could Imitate a Bee', author: 'Richard Connell', image: 'bee.jpg', alt: 'A bee on its side. Graphie Filter.', genre: 'quirky' },
    { code: 'the-outrage', title: 'The Outrage', author: 'Aleksandr I. Kuprin', image: 'gentleman.jpg', alt: 'A man with a suit and cane, tipping his hat. Graphite Filter.', genre: 'literary' },
    { code: 'clancy-of-the-overflow', title: 'Clancy of the Overflow', author: 'Banjo Paterson', image: 'drovers.jpg', alt: 'Three men with wide-brimmed hats riding horses in the outback.', genre: 'poetry' },
    { code: 'the-unexpected', title: 'The Unexpected', author: 'Jack London', image: 'shotgun.jpg', alt: 'A cracked shotgun with smoke rising from the empty barrels. Graphite filter.', genre: 'adventure' },

].map(story => {
    story.image_alt = story.image
    story.genres = [story.genre]

    return story
})


const ROTATORS_ALL = [
    { code: 'the-brazilian-cat', title: 'The Brazilian Cat', author: 'Arthur Conan Doyle', image: 'panther.jpg', alt: `A black panther lounging on the ground. Graphite Filter.`, genre: 'gothic' },
    { code: 'the-country-of-the-blind', title: 'The Country of the Blind', author: 'H. G. Wells', image: 'town.jpg', alt: `A cliffside town. Graphite filter.`, genre: 'adventure' },
    { code: 'the-dream-of-a-ridiculous-man', title: 'The Dream of a Ridiculous Man', author: 'Fyodor Dostoevsky', image: 'earth.jpg', alt: `Earth from space, focusing on Africa and covered in clouds. graphite filter.`, genre: 'literary' },
    { code: 'the-haunted-mind', title: 'The Haunted Mind', author: 'Nathaniel Hawthorne', image: 'bed.jpg', alt: `A large, ornate bed beneath two glass windows. Graphite filter.`, genre: 'gothic' },
    { code: 'the-nightingale', title: 'The Nightingale', author: 'Hans Christan Andersen', image: 'songbird.jpg', alt: `A songbird singing on a tree. Graphite Filter.`, genre: 'childrens' },
    { code: 'celephais', title: 'Celephaïs', author: 'H. P. Lovecraft', image: 'prince.jpg', alt: `A lance-wielding man on an armoured horse. Graphite filter`, genre: 'fantasy' },
    { code: 'no-place-for-a-woman', title: 'No Place for a Woman', author: 'Henry Lawson', image: 'lone.jpg', alt: `A lone horserider staring off into the sky. graphite filter.`, genre: 'tragedy' },
    { code: 'the-shape-of-things', title: 'The Shape of Things', author: 'Ray Bradbury', image: 'pyramid.jpg', alt: `A shiny pyramid on the floor. Graphite filter.`, genre: 'sci-fi' },
    { code: 'song-of-the-future', title: 'Song of the Future', author: 'Banjo Paterson', image: 'kangaroo.jpg', alt: `A kangaroo on a dry plains. Graphite filter`, genre: 'poetry' },
    { code: 'the-stolen-crime', title: 'The Stolen Crime', author: 'Richard Connell', image: 'spectacles.jpg', alt: `Broken spectacles on a wooden floor, zoomed in. Graphite filter.`, genre: 'mystery' },
    { code: 'if-i-were-a-man', title: 'If I Were a Man', author: 'Charlotte Perkins Gilman', image: 'gentleman.jpg', alt: `A man with a suit tipping his hat. Graphite filter.`, genre: 'literary' },
    { code: 'ohallorans-luck', title: 'O’Halloran’s Luck', author: 'Stephen Vincent Benét', image: 'clover.jpg', alt: `A freckled hand holding a shamrock/clover outside. Graphite filter`, genre: 'quirky' },
    { code: 'the-seed-from-the-sepulchre', title: 'The Seed From the Sepulchre', author: 'Clark Ashton Smith', image: 'crocus.jpg', alt: `Small blossoming flowers in the ground. Graphite filter.`, genre: 'fantasy' },
    { code: 'the-night-born', title: 'The Night-Born', author: 'Jack London', image: 'youngwoman.jpg', alt: `A woman with braided hair. Graphite filter`, genre: 'adventure' },
    { code: 'captain-rogers', title: 'Captain Rogers', author: 'W. W. Jacobs', image: 'tired.jpg', alt: `An old, bearded man. Graphite filter.`, genre: 'gothic' },
    { code: 'how-the-whale-got-his-throat', title: 'How The Whale Got His Throat', author: 'Rudyard Kipling', image: 'whale.jpg', alt: `A whale launching out of the sea Graphite filter.`, genre: 'childrens' },
    { code: 'two-little-soldiers', title: 'Two Little Soldiers', author: 'Guy De Maupassant', image: 'pail.jpg', alt: `A bucket being carried by someone in a dress. Graphite filter.`, genre: 'tragedy' },


].map(story => {
    story.image_alt = story.alt
    story.genres = [story.genre]

    return story
})


const ROTATORS_AUTHORS_ALL = [
    { code: 'arthurconandoyle', alt: 'portrait of Arthur Conan Doyle, large moustached and stern..' },
    { code: 'hgwells', alt: 'A portrait of H. G. Wells' },
    { code: 'fyodordostoevsky', alt: 'A photo of Fyodor Dostoevsky sitting in a chair.' },
    { code: 'nathanielhawthorne', alt: 'A portrait of Nathaniel Hawthorne.' },
    { code: 'hanschristianandersen', alt: 'A portrait of Hans Christian Andersen.' },
    { code: 'hplovecraft', alt: 'A portrait of H. P. Lovecraft, long-faced in a suit.' },
    { code: 'henrylawson', alt: 'A portrait of Henry Lawson' },
    { code: 'raybradbury', alt: 'A portrait of Ray Bradbury.' },
    { code: 'banjopaterson', alt: 'A portrait of Banjo Paterson, dashing-looking.' },
    { code: 'richardconnell', alt: 'A portrait of Richard Connell.' },
    { code: 'charlotteperkinsgilman', alt: 'A portrait of Charlotte Perkins Gilman.' },
    { code: 'stephenvincentbenet', alt: 'A portrait of Stephen Vincent Benet.' },
    { code: 'clarkashtonsmith', alt: 'A portrait of Clark Ashton Smith.' },
    { code: 'jacklondon', alt: 'A portrait of Jack London.' },
    { code: 'wwjacobs', alt: 'A portrait of W. W. Jacobs' },
    { code: 'rudyardkipling', alt: 'A portrait of Rudyard Kipling.' },
    { code: 'guydemaupassant', alt: 'A portrait of Guy De Maupassant.' }

   
]


// let rotator_start = Math.floor(Math.random() * ROTATORS_AUTHORS_ALL.length)
let rotator_start = 0


const ROTATORS = ROTATORS_ALL.concat(ROTATORS_ALL)
    .slice(rotator_start, 12 + rotator_start)


const ROTATORS_AUTHORS = ROTATORS_AUTHORS_ALL.concat(ROTATORS_AUTHORS_ALL)
    .slice(rotator_start, 12 + rotator_start)