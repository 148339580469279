import m, { route, redraw } from 'mithril'

import Menu from '../models/Menu'

import settings_menu from './components/forms/settings'
import follow_links from './components/follow'
import { sun, moon, cog } from './components/icons'

import { capitalise_first } from '../logic/Formatter'
import breadcrumbs from './components/breadcrumbs'

//This is the layout that wraps all pages

//It has two parts

//1: Top bar, shows title and page links

//2: Bottom bar, shows copyright and email
//It is always hidden until the viewer scrolls, even if the page takes up less space than the screen size


const Page = {

    oninit: vnode => {
        vnode.state.dark_mode = localStorage.getItem('dark_mode') == 'true'
        vnode.state.dyslexia_font = localStorage.getItem('dyslexia') == 'y'
        vnode.state.hide_ratings_enabled = localStorage.getItem('hide_ratings_enabled') == 'true'

        vnode.state.settings_menu_active = false
    },

    oncreate: vnode => {

        vnode.state.content_scrolled = false

        // let content_element = document.getElementById('content')
        let content_element = document.getElementById('website')

        content_element.onscroll = () => {

            if (content_element.scrollTop == 0 && vnode.state.content_scrolled == true) {
                vnode.state.content_scrolled = false
                redraw()
            }
            else if (content_element.scrollTop != 0 && vnode.state.content_scrolled == false) {
                vnode.state.content_scrolled = true
                redraw()
            }
        }
    },

    view: function (vnode) {

        const has_breadcrumb_links = Menu.get_page()?.breadcrumb_links

        return [

            m('.website',
                {
                    oninit: () => {
                        //Fix for address bar changing the height on phones


                        // window.addEventListener('resize', () => {
                        //     document.getElementById('website').style.height = window.innerHeight + 'px'
                        // })
                    },
                    id: 'website',
                    class: `${vnode.state.dark_mode ? 'dark_mode' : 'light_mode'} \
${vnode.state.dyslexia_font ? 'open_dyslexic' : ''} \
${vnode.state.content_scrolled ? 'page_scrolled' : ''} \
${vnode.state.hide_ratings_enabled ? 'hide_ratings' : ''}`

                },

                m(top_section, {
                    settings_menu_active: vnode.state.settings_menu_active,
                    change_settings_menu: (new_val) => { vnode.state.settings_menu_active = new_val },

                    dark_mode: vnode.state.dark_mode,
                    change_dark_mode: (new_val) => { vnode.state.dark_mode = new_val },

                    dyslexia_font: vnode.state.dyslexia_font,
                    change_dyslexia_font: (new_val) => { vnode.state.dyslexia_font = new_val },

                    hide_ratings_enabled: vnode.state.hide_ratings_enabled,
                    change_hide_ratings_enabled: (new_val) => { vnode.state.hide_ratings_enabled = new_val },
                }),

                has_breadcrumb_links ? m(breadcrumbs) : [],

                vnode.children,

                m(footers)
            )
        ]
    }
}

const top_section = {
    view: vnode => {
        return [
            m('.top_section',
                {
                    class: (Menu.content_size == 1 ? 'unsticky' : 'sticky')
                },

                m(header),

                m(nav_bar),

                m(dark_mode_button, {
                    dark_mode: vnode.attrs.dark_mode,
                    change_dark_mode: vnode.attrs.change_dark_mode
                }),

                m(settings_button, {
                    settings_menu_active: vnode.attrs.settings_menu_active,
                    change_settings_menu: vnode.attrs.change_settings_menu
                }),

                vnode.attrs.settings_menu_active ?

                    m(settings_menu, {
                        close: () => vnode.attrs.change_settings_menu(false),
                        turn_on_dyslexica_font: () => { vnode.attrs.change_dyslexia_font(true) },
                        turn_off_dyslexica_font: () => { vnode.attrs.change_dyslexia_font(false) },
                        change_dark_mode: vnode.attrs.change_dark_mode,
                        change_hide_ratings_enabled: vnode.attrs.change_hide_ratings_enabled,

                    })
                    : []
            )
        ]
    }
}


const header = {
    view: vnode => {
        return [
            m("header.top_bar",
                {
                    id: 'top_bar',
                },

                m('.title_and_logo_wrapper',
                    m('a.title_and_logo',
                        {
                            href: '/'
                        },
                        m('img.logo', {
                            src: 'https://libraryofshortstories.s3.ap-southeast-2.amazonaws.com/small/books-180x180.png',
                            alt: 'A pixel image of differently coloured books.'
                        }),
                        m('.title',
                            m('span.logo_l', 'L'),
                            m('span.logo_i', 'i'),
                            m('span.logo_b', 'b'),
                            "rary of Short Stories"
                        )
                    )
                )
            )
        ]
    }
}

const nav_bar = {
    view: vnode => {
        return [
            m('nav.page_bar',

                m('.pages',

                    //Single Links
                    Menu.pages.filter(p => p.topbarlink && !p.parent).map(page =>
                        m(menu_title, { page: page })
                    ),

                    //Dropdown Links
                    Menu.dropdowns().map(dropdown =>
                        m(menu_section, { dropdown: dropdown })
                    )
                )
            )
        ]
    }
}

const menu_title = {
    view: vnode => {
        let page = vnode.attrs.page
        return [
            m('a.menu_title',
                {
                    href: page.route,
                    class: `${page.title == 'Home' ? 'd_computer' : ''} ${route.get() == page.route ? 'active' : ''}`,
                },
                page.title
            )
        ]
    }
}

const menu_section = {

    view: vnode => {
        let dropdown = vnode.attrs.dropdown

        let semi_active = dropdown.pages.some(page => Menu.is_route(page.route)) && (route.get() != '/')

        return [
            m('div.remove_button_styling.menu_section',
                {
                    id: 'Menu_section_' + dropdown.title,

                    oncreate: vnode2 => {
                        let content_element = document.getElementById('Menu_section_' + dropdown.title)

                        let listener = (event) => {

                            event.stopPropagation();
                        }

                        content_element.addEventListener('click', listener)
                        document.addEventListener('click', event => {
                            if (Menu.opened) {
                                Menu.opened = false
                                m.redraw()
                            }
                        })
                    },

                    onfocusout: (e) => {
                        let classnames = e.relatedTarget?.className
                        if (classnames?.includes('page_link')) {
                            return
                        }
                        Menu.opened = false
                    },
                    onmouseenter: e => {
                        Menu.opened = dropdown.title
                    },
                    onmouseleave: e => {
                        Menu.opened = false
                    },
                    class: `${semi_active ? 'semi_active' : ''} ${Menu.opened === dropdown.title ? 'opened' : ''}`,
                },
                m('a.remove_button_styling.menu_title',

                    {
                        href: dropdown.pages.find(p => p.default).route,
                        onfocus: e => {
                            Menu.opened = dropdown.title
                        },
                        onclick:
                            Menu.opened == dropdown.title ? (e) => { } : (e) => {
                                Menu.opened = dropdown.title
                                e.preventDefault()
                                return false
                            }

                    },
                    dropdown.title,

                ),
                m('.dropdown',
                    {
                        id: dropdown.title,
                        //This might help SEO see the links, IDK
                    },
                    dropdown.pages.map(page => m(page_link, { page: page }))
                )
            )
        ]
    }
}

const dark_mode_button = {
    view: vnode => {
        return [
            m('button.dark_mode_button',
                {
                    id: 'dark-toggle',
                    onclick: () => {
                        let new_val = !vnode.attrs.dark_mode
                        localStorage.setItem('dark_mode', new_val)
                        vnode.attrs.change_dark_mode(new_val)
                    },
                    title: vnode.attrs.dark_mode ? 'Dark mode' : 'Light mode',
                },
                m(vnode.attrs.dark_mode ? moon : sun)
            )
        ]
    }
}

const settings_button = {
    view: vnode => {
        return [
            m('button.settings_button',
                {
                    title: 'Settings',
                    onclick: () => vnode.attrs.change_settings_menu(!vnode.attrs.settings_menu_active)
                },
                m(cog)
            )
        ]
    }
}

const page_link = {
    view: vnode => {
        let page = vnode.attrs.page
        let active = Menu.is_route(page.route) && (route.get() == '/' || page.route != '/')
        let status = active ? 'active' : ''
        let size = page.size || ''

        return [
            [
                m('a.page_link',
                    {
                        href: page.route,
                        class: `${status} ${size}`,

                    },
                    page.title
                ),
                page.break_after ? m('.separate_top.white_border') : []
            ]
        ]
    }
}

const footers = {
    view: vnode => {
        return [

            !route.get().includes('/onlinereader') ?
                m('footer.copyright_bar',

                    m(follow_links),

                    m('b.copyright.absolute_centre', '© 2020 - 2025 '),

                ) : [],

            m('footer.copyright_bar.second_copyright_bar',
                m('.footer_sections.p-10.flex_wrap',
                    m('.footer_section',
                        m('h3', 'Browse'),
                        m('.links',
                            m('.', m('a.my_link', { href: '/stories' }, 'Library')),
                            m('.', m('a.my_link', { href: '/authors' }, 'Authors')),
                            m('.', m('a.my_link', { href: '/reviews' }, 'Recommendations')),
                        )),


                    m('.footer_section',
                        m('h3', 'Genres'),
                        m('.links',
                            all_genres.map(genre =>
                                m('.', m('a.my_link', { href: '/collection/' + genre }, capitalise_first(genre))),
                            )
                        )
                    ),

                    m('.footer_section',
                        m('h3', 'Collections'),
                        m('.links',
                            [
                                { code: 'classics', title: 'The Classics' },
                                { code: 'choice', title: 'Librarian’s Picks' },
                            ].map(collection =>
                                m('.', m('a.my_link', { href: '/' + collection.code }, collection.title)),
                            ),
                            [
                                { code: 'five-minute-stories', title: '5 Minute Stories' },
                                { code: 'fifteen-minute-stories', title: '15 Minute Stories' },
                            ].map(collection =>
                                m('.', m('a.my_link', { href: '/' + collection.code }, collection.title)),
                            ),

                            [
                                { code: 'collection/happiness', title: 'The Pursuit of Happiness' },
                                { code: 'collection/dreams', title: 'Living the Dream' },
                                { code: 'collection/too-far', title: 'Science Gone Too Far' },
                                { code: 'collection/monsters', title: 'Horror Monsters' },
                                { code: 'collection/urban-despair', title: 'Urban Despair' },
                                // { code: 'collection/ghosts', title: 'Ghost Stories' },
                                // { code: 'collection/gut-wrenchers', title: 'Gut Wrenchers' },
                                { code: 'collection/space', title: 'To Boldly Go' },
                                { code: 'collection/romance', title: 'A Quick Romance' },
                            ].map(collection =>
                                m('.', m('a.my_link', { href: '/' + collection.code }, collection.title)),
                            )
                        )
                    ),
                    m('.footer_section',
                        m('h3', 'About'),
                        m('.links',
                            m('.', m('a.my_link', { href: '/about' }, 'The Website')),
                            m('.', m('a.my_link', { href: '/about-the-stories' }, 'The Stories')),
                            m('.', m('a.my_link', { href: '/furtherreading' }, 'Further Reading')),
                            m('.', m('a.my_link', { href: '/how-to-use' }, 'Accessibility')),

                            m('.', m('a.my_link', { href: '/privacy' }, 'Privacy')),
                        )
                    )
                )
            )
        ]
    }
}

let all_genres = [
    'adventure',
    'childrens',
    'fantasy',
    'gothic',
    'horror',
    'literary',
    'mystery',
    'poetry',
    'quirky',
    'sci-fi',
    'tragedy',
    'war'
]

export default Page