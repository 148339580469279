import { redraw, route } from "mithril";
import { capitalise_first } from "../logic/Formatter";

const base_canonical_url = 'https://www.libraryofshortstories.com/'

const Menu = {
    content_size: 1,

    //1 ???
    //2 ???
    //3 ???

    is_route: (given_route) => {
        //console.log(route.get(), given_route, route.get().includes(given_route))
        return route.get() == given_route
        //return route.get().includes(given_route)
    },

    route: (pageroute, params = {}) => {
        Menu.fadingout = true
        redraw()
        setTimeout(() => {
            Menu.fadingout = false
            route.set(pageroute, params)
            document.getElementById('content').scrollTop = 0;

            let canonical = document.querySelector('link[rel="canonical"]');
            if (canonical !== null) {
                canonical.href = base_canonical_url + pageroute;
            }

        }, 300);
    },

    get_pages: () => Menu.pages,
    get_page: (given_route) => {
        if (!given_route) {
            let changed_route = route.get().split('?')[0]
            changed_route = changed_route.split('#')[0]
            changed_route = changed_route.split('/')
            given_route = '/' + changed_route[1]
        }

        let page = Menu.pages.find(page => page.route == given_route)
        if (!page) {
            console.error(`No page for ${given_route}`)
        }
        return page
    },
    //Data also stored on server
    //Either rely on server info or...
    //Edit both (Don't leave one set of data out of date)
    pages: [
        { route: '/', title: "Home", topbarlink: true },
        
        { route: '/stories', title: "Library", topbarlink: true },

        { route: '/authors', title: "Authors", topbarlink: true },

        { route: '/discover', title: 'Discover', topbarlink: true, parent: 'Discover', default: true, break_after: true },//, break_after: true },

        { route: '/classics', title: 'The Classics', topbarlink: true, parent: 'Discover', breadcrumb_links: ['/discover'] },//, break_after: true },
        { route: '/choice', title: 'Librarian’s Picks', topbarlink: true, parent: 'Discover', break_after: true, breadcrumb_links: ['/discover'] },

        { route: '/genres', title: 'Best of Genre', topbarlink: true, parent: 'Discover', breadcrumb_links: ['/discover'] },//, break_after: true },
        // { route: '/authors', title: "Authors", topbarlink: true, parent: 'Discover' },
        { route: '/collections', title: 'Themed Collections', topbarlink: true, parent: 'Discover', breadcrumb_links: ['/discover'] },//, break_after: true },
        { route: '/series', title: 'Series', topbarlink: true, parent: 'Discover', breadcrumb_links: ['/discover'] },//, break_after: true },
        { route: '/reviews', title: "Recommended", topbarlink: true, parent: 'Discover', breadcrumb_links: ['/discover'] },

        // { route: '/choice', title: "Librarian’s Choice", topbarlink: false, parent: 'Discover', size: 'small' },
        // { route: '/classics', title: "The Classics", topbarlink: false, parent: 'Discover', size: 'small' },
        { route: '/five-minute-stories', title: "5 Minute Stories", topbarlink: false, breadcrumb_links: ['/discover'] },
        { route: '/fifteen-minute-stories', title: "15 Minute Stories", topbarlink: false, breadcrumb_links: ['/discover'] },


        // { route: '/experiment', title: "Experiment" },


        { route: '/about', title: "The Website", topbarlink: true, parent: 'About', default: true },
        { route: '/about-the-stories', title: "The Stories", topbarlink: true, parent: 'About' },
        { route: '/furtherreading', title: "Further Reading", topbarlink: true, parent: 'About' },
        { route: '/how-to-use', title: "Accessibility", topbarlink: true, parent: 'About' },
        { route: '/privacy', title: "Privacy", topbarlink: true, parent: 'About' },

        {
            route: '/onlinereader', subroute: '/:story',
            //title: "Online Reader"
        },
        {
            route: '/author', subroute: '/:author',
            //title: "Author"
        },
        {
            route: '/review', subroute: '/:review',
            //title: "Recommended"
        },
        {
            route: '/collection', subroute: '/:collection',
            //    title: "Collection"
        },
    ],

    dropdowns: () => {
        let o = Menu.get_pages()
            .filter(page => page.topbarlink && page.parent)
            .reduce((acc, page) => {
                acc[page.parent] = acc[page.parent] || []
                acc[page.parent].push(page)
                return acc
            }, {})

        return Object.keys(o).map(key => ({
            title: key,
            pages: o[key]
        }))
    }
}


// Genres.list
let top_genres = ['horror', 'quirky', 'literary', 'childrens']
top_genres.forEach(genre => {
    Menu.pages.push({
        route: '/collection/' + genre,
        file: false,
        title: capitalise_first(genre),
        topbarlink: false,
        parent: 'Discover',
        size: 'small'
    })
})


export default Menu